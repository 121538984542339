import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import {
  promotionalCodeFilterDirections,
  promotionalCodeFilterOptions,
  PromotionalCodeFilterValue,
} from "../PromotionalCodeFilterBar";

export function usePromotionalCodeFilter(): [
  PromotionalCodeFilterValue,
  (change: PromotionalCodeFilterValue) => void
] {
  const history = useHistory();
  const location = useLocation();

  const parseParams = () => {
    const params = new URLSearchParams(location.search);
    const orderBy = params.get("orderBy") ?? "createdAt";
    const direction = params.get("direction") ?? "desc";
    const code = params.get("code") ?? "";
    const showActive = params.get("showActive") === "true";

    return {
      orderBy: promotionalCodeFilterOptions.includes(orderBy as any)
        ? orderBy
        : "createdAt",
      direction: promotionalCodeFilterDirections.includes(direction as any)
        ? direction
        : "desc",
      code,
      showActive,
    } as PromotionalCodeFilterValue;
  };

  const [value, setValue] = useState<PromotionalCodeFilterValue>(parseParams);

  useEffect(() => {
    history.push(
      location.pathname.replace(/\?.*$/, "") +
        "?" +
        new URLSearchParams(
          Object.entries(value).filter(([_, val]) => !!val)
        ).toString()
    );
  }, [value]);

  return [value, setValue];
}
