import { useCallback, useEffect, useRef, useState } from "react";
import {
  FindAllPromotionalCodesParams,
  PromotionalCode,
  UpdatePromotionalCodeDto,
} from "src/api/pangaeaTypes";
import { pangaeaV1API } from "src/api/pangaeaV1API";
import { useSkipMount } from "src/util/hooks";
import usePrevious from "src/util/hooks/usePrevious";

export interface UsePromotionalCodesOpts
  extends Pick<FindAllPromotionalCodesParams, "order" | "orderBy" | "limit"> {
  codeName?: string;
  showActive?: boolean;
}

export interface UsePromotionalCodesResult {
  error: any;
  next?: () => void;
  previous?: () => void;
  isLoading: boolean;
  page: number;
  count: number;
  pages: number;
  data: PromotionalCode[] | undefined;
  updatePromotionalCode: (code: string, dto: UpdatePromotionalCodeDto) => void;
  deletePromotionalCode: (code: string) => void;
}

export interface UsePromotionalCodes {
  (opts: UsePromotionalCodesOpts): UsePromotionalCodesResult;
}

const usePromotionalCodes: UsePromotionalCodes = ({
  order = "desc",
  orderBy = "createdAt",
  limit = 20,
  codeName,
  showActive,
}) => {
  const [cursor, setCursor] = useState<string>("");
  const cursorStack = useRef<string[]>([]);

  const mounted = useSkipMount();

  useEffect(() => {
    if (!mounted) {
      return;
    }

    cursorStack.current = [];
    setCursor("");
  }, [order, orderBy, limit, codeName, showActive]);

  const {
    refetch: refetchCount,
    data: countResponse,
    error: countQueryError,
  } = pangaeaV1API.useGetPromotionalCodeCountQuery({ active: showActive });
  const {
    refetch: refetchPromotionalCodes,
    data: promotionalCodesQueryResponse,
    error: promotionalCodesQueryError,
    isFetching: promotionalCodesLoading,
  } = pangaeaV1API.useGetPromotionalCodesQuery(
    {
      limit,
      startAfterId: cursor || undefined,
      order,
      orderBy,
      active: showActive || undefined,
    },
    {
      skip: !!codeName,
    }
  );
  const {
    data: singularCode,
    error: singularCodeError,
    isFetching: singularCodeLoading,
    refetch: refetchSingleCode,
  } = pangaeaV1API.useGetPromotionalCodeQuery(codeName ?? "", {
    skip: !codeName,
  });
  const [
    updatePromotionalCode,
    {
      error: updatePromotionalCodeError,
      isLoading: updatePromotionalCodeLoading,
    },
  ] = pangaeaV1API.useUpdatePromotionalCodeMutation();
  const [
    deletePromotionalCode,
    {
      error: deletePromotionalCodeError,
      isLoading: deletePromotionalCodeLoading,
    },
  ] = pangaeaV1API.useDeletePromotionalCodeMutation();

  const prevCodeName = usePrevious(codeName);
  useEffect(() => {
    if (prevCodeName && !codeName) {
      refetchPromotionalCodes();
      refetchSingleCode();
    }
  }, [codeName]);

  const next = useCallback(() => {
    const nextCursor =
      promotionalCodesQueryResponse?.[promotionalCodesQueryResponse.length - 1]
        ?.code;

    if (!nextCursor) {
      return;
    }

    cursorStack.current.push(cursor);
    setCursor(nextCursor);
  }, [cursor, promotionalCodesQueryResponse, cursorStack]);

  const previous = useCallback(() => {
    const previousCursor = cursorStack.current.pop();
    setCursor(previousCursor ?? "");
  }, [cursorStack.current]);

  const getData = () => {
    if (codeName) {
      if (
        singularCodeError ||
        (singularCodeLoading && codeName !== singularCode?.code)
      ) {
        return undefined;
      }

      return singularCode ? [singularCode] : undefined;
    }

    return promotionalCodesQueryResponse;
  };

  return {
    next:
      promotionalCodesQueryResponse?.length === limit && !codeName
        ? next
        : undefined,
    previous:
      cursorStack.current.length > 0 && !codeName ? previous : undefined,
    pages: countResponse?.count ? Math.ceil(countResponse.count / limit) : 0,
    page: cursorStack.current.length + 1,
    data: getData(),
    updatePromotionalCode: async (
      code: string,
      dto: UpdatePromotionalCodeDto
    ) => {
      try {
        await updatePromotionalCode({ ...dto, code }).unwrap();
        (codeName ? refetchSingleCode : refetchPromotionalCodes)();
      } catch (error) {
        console.error(error);
      }
    },
    deletePromotionalCode: async (code: string) => {
      try {
        await deletePromotionalCode(code).unwrap();
        (codeName ? refetchSingleCode : refetchPromotionalCodes)();
        refetchCount();
      } catch (error) {
        console.error(error);
      }
    },
    count: countResponse?.count ?? 0,
    isLoading:
      promotionalCodesLoading ||
      updatePromotionalCodeLoading ||
      deletePromotionalCodeLoading ||
      singularCodeLoading,
    error:
      countQueryError ||
      promotionalCodesQueryError ||
      updatePromotionalCodeError ||
      deletePromotionalCodeError ||
      (singularCodeError && (singularCodeError as any)?.status !== 404)
        ? singularCodeError
        : undefined,
  };
};

export { usePromotionalCodes };
