import {
  Box,
  Container,
  LinearProgress,
  TablePagination,
} from "@material-ui/core";
import React, { FC, useEffect, useState } from "react";
import { PromotionalCode } from "src/api/pangaeaTypes";
import { PromotionalCodeEditModal } from "./PromotionalCodeEditModal";
import { PromotionalCodeErrorModal } from "./PromotionalCodeErrorModal";
import { PromotionalCodeFilterBar } from "./PromotionalCodeFilterBar";
import { PromotionalCodeViewerTable } from "./PromotionalCodeViewerTable";
import { usePromotionalCodeFilter } from "./util/usePromotionalCodeFilter";
import { usePromotionalCodes } from "./util/usePromotionalCodes";

export interface PromotionalCodeViewerProps {
  products: { id: string; name: string }[];
}

const limit = 20;

export const PromotionalCodeViewer: FC<PromotionalCodeViewerProps> = (
  props
) => {
  const [filterState, setFilterState] = usePromotionalCodeFilter();
  const [editing, setEditing] = useState<PromotionalCode | null>(null);
  const [errorDismissed, setErrorDismissed] = useState(false);

  const {
    data,
    isLoading,
    page,
    count,
    error,
    next,
    previous,
    updatePromotionalCode,
    deletePromotionalCode,
  } = usePromotionalCodes({
    limit,
    orderBy: filterState.orderBy,
    order: filterState.direction,
    codeName: filterState.code || undefined,
    showActive: filterState.showActive,
  });

  useEffect(() => {
    setErrorDismissed(false);
  }, [error]);

  return (
    <Box paddingBottom="52px">
      <Container maxWidth="md">
        <PromotionalCodeFilterBar
          value={filterState}
          handleOrderByChange={(change) =>
            setFilterState({ ...filterState, ...change })
          }
        />
      </Container>
      {data?.length && (
        <PromotionalCodeViewerTable
          disabled={isLoading || !!editing}
          handleEdit={(code) =>
            setEditing(data.find((p) => p.code === code) ?? null)
          }
          handleDelete={(code) => {
            if (confirm(`Are you sure you want to delete ${code}?`)) {
              deletePromotionalCode(code);
            }
          }}
          handleToggleActivation={(code) => {
            const promotionalCode = data.find((p) => p.code === code);
            if (promotionalCode) {
              updatePromotionalCode(code.trim(), {
                redemptions: promotionalCode.redemptions, // BUG: redemptions parameter should be optional
                deactivated: !promotionalCode.deactivated,
              });
            }
          }}
          promotionalCodes={data}
          products={props.products}
        />
      )}
      <Box
        position="fixed"
        bottom={0}
        width="calc(100% - 239px)"
        bgcolor="white"
      >
        <LinearProgress variant="indeterminate" hidden={!isLoading} />
        <Container maxWidth="md">
          <TablePagination
            component={"div"}
            rowsPerPage={limit}
            rowsPerPageOptions={[limit]}
            page={page - 1}
            count={count}
            onPageChange={(_, nextPage) => {
              let _page = page - 1;
              if (nextPage === _page) {
                return;
              }
              if (nextPage < _page) {
                previous?.();
              } else {
                next?.();
              }
              window.scroll(0, 0);
            }}
          />
        </Container>
      </Box>
      <PromotionalCodeEditModal
        promotionalCode={editing ?? undefined}
        handleSubmit={({ code, ...body }) => {
          updatePromotionalCode(editing?.code ?? "", {
            ...body,
            redemptions: editing?.redemptions ?? 0,
          });
          setEditing(null);
        }}
        onClose={() => setEditing(null)}
        products={props.products}
      />
      <PromotionalCodeErrorModal
        error={error}
        open={error && !errorDismissed}
        onClose={() => setErrorDismissed(true)}
      />
    </Box>
  );
};
