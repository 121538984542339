import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { DesignAssignment } from "@yardzen-inc/models";
import firebase from "firebase/compat/app";
import {
  BuildRegion,
  BuildRegionsByContractorIdPathParams,
  BuildRegionsByIdPathParams,
  BuildRegionsUpdatePathParams,
  Codes,
  Contractor,
  ContractorDto,
  ContractorMatchData,
  CreateBuildRegionsDto,
  CreateCodeDto,
  CreateContractorDto,
  CreateManyPromotionalCodesDto,
  CreateReferralCodeDto,
  FindAllContractorsQueryParams,
  FindAllPromotionalCodesParams,
  FindAllReferralCodesQueryParams,
  FindContractorsByLatLngQueryParams,
  MatchHeaderData,
  MatchPageHeaderByProfileIdPathParams,
  MatchPageHeaderByProjectIdPathParams,
  MatchTableDataByMarketIdPathParams,
  MatchTableDataByMarketIdQueryParams,
  MatchTableDataByZipCodeQueryParams,
  MatchTableDataByZipCodeV3PathParams,
  PromotionalCode,
  PromotionalCodeCount,
  ReferralCode,
  SetDesignAssignmentDueDateArguments,
  UpdateBuildRegionsDto,
  UpdateContractorDto,
  UpdateContractorPathParams,
  UpdateProjectContractorMatchStatusDto,
  UpdateProjectContractorMatchStatusPathParams,
  UpdateProjectDto,
  UpdateProjectPathParams,
  UpdatePromotionalCodeDto,
  UpdateReferralCodeDto,
  UpdateReferralCodePathParams,
} from "./pangaeaTypes";
import { paths } from "./pangaeaTypes.generated";

export const PANGAEA_V1_BASE_URL =
  process.env["REACT_APP_PANGAEA_BASE_URL"] || "http://localhost:7777/v1/";

enum ApiTags {
  CODES = "CODES",
  REFERRALCODES = "REFERRALCODES",
  CONTRACTORS = "CONTRACTORS",
  MATCHING = "MATCHING",
  MATCH_STATUS = "MATCH_STATUS",
  PROMOTIONAL_CODES = "PROMOTIONAL_CODES",
  PROJECTS = "PROJECTS",
  DESIGN_ASSIGNMENTS = "DESIGN_ASSIGNMENTS",
  BUILD_REGIONS = "BUILD_REGIONS",
}

export const pangaeaV1API = createApi({
  reducerPath: "pangaeaAPI",
  baseQuery: fetchBaseQuery({
    baseUrl: PANGAEA_V1_BASE_URL,
    prepareHeaders: async (headers) => {
      const user = firebase.auth()?.currentUser;
      if (user) {
        headers.set("Authorization", `Bearer ${await user.getIdToken()}`);
      }

      return headers;
    },
  }),

  tagTypes: [
    ApiTags.CODES,
    ApiTags.REFERRALCODES,
    ApiTags.CONTRACTORS,
    ApiTags.MATCHING,
    ApiTags.MATCH_STATUS,
    ApiTags.PROMOTIONAL_CODES,
    ApiTags.PROJECTS,
    ApiTags.DESIGN_ASSIGNMENTS,
    ApiTags.BUILD_REGIONS,
  ],
  endpoints: (builder) => ({
    getSelfAssignQueue: builder.query<
      paths["/v1/design-assignments/queue"]["get"]["responses"]["200"]["content"]["application/json"],
      unknown
    >({
      query: () => ({
        url: "design-assignments/queue",
        method: "GET",
      }),
    }),
    createCode: builder.mutation<Codes, CreateCodeDto>({
      query: (body) => ({ url: `codes`, method: "POST", body }),
      invalidatesTags: [ApiTags.CODES],
    }),
    getReferralCodes: builder.query<
      ReferralCode[],
      FindAllReferralCodesQueryParams
    >({
      query: (params) => {
        let url = "referral-codes";
        const paramKeys = Object.keys(params);

        if (paramKeys.length) {
          const queryStringArr: string[] = [];
          paramKeys.forEach((key: string) => {
            queryStringArr.push(
              `${key}=${params[key as keyof FindAllReferralCodesQueryParams]}`
            );
          });

          // example url: referral-codes?referrerId=123&limit=100'
          url = `${url}?${queryStringArr.join("&")}`;
        }

        return {
          url,
          method: "GET",
        };
      },
      providesTags: [ApiTags.REFERRALCODES],
    }),
    createReferralCode: builder.mutation<ReferralCode, CreateReferralCodeDto>({
      query: (body) => ({ url: `referral-codes`, method: "POST", body }),
      invalidatesTags: [ApiTags.REFERRALCODES],
    }),
    updateReferralCode: builder.mutation<
      // The referral code PATCH endpoint returns nothing on success,
      // so we use unknown to show that.
      unknown,
      UpdateReferralCodeDto & UpdateReferralCodePathParams
    >({
      query: ({ id, ...updateReferralCodeDto }) => ({
        url: `referral-codes/${id}`,
        method: "PATCH",
        body: updateReferralCodeDto,
      }),
      invalidatesTags: [ApiTags.REFERRALCODES],
    }),
    //CONTRACTORS
    getContractors: builder.query<
      ContractorDto[],
      FindAllContractorsQueryParams
    >({
      query: (params) => ({
        url: "contractors",
        method: "GET",
        params,
      }),
      providesTags: [ApiTags.CONTRACTORS],
    }),
    getContractor: builder.query<ContractorDto, string>({
      query: (id) => ({
        url: `contractors/${id}`,
        method: "GET",
      }),
      providesTags: [ApiTags.CONTRACTORS],
    }),
    createContractor: builder.mutation<Contractor, CreateContractorDto>({
      query: (body) => ({ url: `contractors`, method: "POST", body }),
      invalidatesTags: [ApiTags.CONTRACTORS],
    }),
    updateContractor: builder.mutation<
      boolean,
      UpdateContractorDto & UpdateContractorPathParams
    >({
      query: ({ id, ...body }) => ({
        url: `contractors/${id}`,
        method: "PATCH",
        body,
      }),
      invalidatesTags: [ApiTags.CONTRACTORS],
    }),
    //FILTERING BY LAT/LNG
    getContractorsByLatLng: builder.query<
      Contractor[],
      FindContractorsByLatLngQueryParams
    >({
      query: (params) => {
        let url = "contractors/within-polygons";
        const paramKeys = Object.keys(params);

        if (paramKeys.length) {
          const queryStringArr: string[] = [];
          paramKeys.forEach((key: string) => {
            queryStringArr.push(
              `${key}=${
                params[key as keyof FindContractorsByLatLngQueryParams]
              }`
            );
          });

          // example url: contractors/within-polygons?lat=-123&lng=30'
          url = `${url}?${queryStringArr.join("&")}`;
        }

        return {
          url,
          method: "GET",
        };
      },
      providesTags: [ApiTags.CONTRACTORS],
    }),
    //MATCHING MARKETS
    getMatchPageHeaderByProfileId: builder.query<
      MatchHeaderData,
      MatchPageHeaderByProfileIdPathParams
    >({
      query: ({ profileId }) => ({
        url: `profiles/${profileId}/match-header`,
        method: "GET",
      }),
      providesTags: [ApiTags.MATCHING],
    }),
    getMatchPageHeaderByProjectId: builder.query<
      MatchHeaderData,
      MatchPageHeaderByProjectIdPathParams
    >({
      query: ({ projectId }) => ({
        url: `projects/${projectId}/match-header`,
        method: "GET",
      }),
      providesTags: [ApiTags.MATCHING],
    }),
    // TODO: remove unused request
    getMatchTableDataByZipCode: builder.query<
      ContractorMatchData,
      MatchTableDataByZipCodeQueryParams & { zipCode: string }
    >({
      query: (params) => {
        const url = new URL("zip-codes/", PANGAEA_V1_BASE_URL);

        url.pathname = url.pathname + `${params.zipCode}/contractors`;

        if (params.ids) {
          url.searchParams.append("ids", params.ids);
        }
        if (params.projectId) {
          url.searchParams.append("projectId", params.projectId);
        }

        return {
          url: url.toString(),
          method: "GET",
        };
      },
      providesTags: [ApiTags.MATCHING],
    }),
    // TODO: remove unused request
    getMatchTableDataByMarketId: builder.query<
      ContractorMatchData,
      MatchTableDataByMarketIdPathParams & MatchTableDataByMarketIdQueryParams
    >({
      query: (params) => {
        const url = new URL("markets/", PANGAEA_V1_BASE_URL);

        url.pathname = url.pathname + `${params.marketId}/contractors`;

        if (params) {
          const paramKeys = Object.keys(params);

          //delete zipCode from paramKeys
          paramKeys.splice(paramKeys.indexOf("marketId"), 1);

          if (paramKeys.length) {
            paramKeys.forEach((key: string) => {
              const val =
                params[key as keyof MatchTableDataByMarketIdQueryParams];
              if (val !== undefined) {
                url.searchParams.append(key, val);
              }
            });

            // example url: markets/15237/contractors?ids=KpN58SvnoDQRQaKHol3U,7318zQjtE7tvHZdlFkeE&projectId=kBtNr3ymfry8U1AjREnE
          }
        }

        return {
          url: url.toString(),
          method: "GET",
        };
      },
      providesTags: [ApiTags.MATCHING],
    }),
    getMatchTableDataByZipCodeV3: builder.query<
      ContractorMatchData,
      MatchTableDataByZipCodeV3PathParams
    >({
      query: ({ zipCode, projectId, profileId, body }) => ({
        url: `zip-codes/${zipCode}/projects/${projectId}/profiles/${profileId}`,
        method: "POST",
        body,
      }),
      providesTags: [ApiTags.MATCHING],
    }),
    // MATCH STATUS
    updateProjectContractorMatchStatus: builder.mutation<
      any,
      UpdateProjectContractorMatchStatusDto &
        UpdateProjectContractorMatchStatusPathParams
    >({
      query: ({ projectId, contractorId, ...body }) => ({
        url: `projects/${projectId}/match-status/contractors/${contractorId}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: [ApiTags.CONTRACTORS],
    }),
    getPromotionalCode: builder.query<PromotionalCode, string>({
      query: (code: string) => ({
        url: `promotional-codes/${code}`,
        method: "GET",
      }),
    }),
    getPromotionalCodes: builder.query<
      PromotionalCode[],
      FindAllPromotionalCodesParams
    >({
      query: (query) => ({
        url: `promotional-codes`,
        method: "GET",
        params: query,
      }),
    }),
    getPromotionalCodeCount: builder.query<
      PromotionalCodeCount,
      { active?: boolean }
    >({
      query: (params) => ({
        url: `promotional-codes/count${
          params?.active !== undefined ? `?active=${params.active}` : ""
        }`,
        method: "GET",
      }),
    }),
    batchCreatePromotionalCodes: builder.mutation<
      void,
      CreateManyPromotionalCodesDto
    >({
      query: (dto) => ({
        url: `promotional-codes/batch`,
        method: "POST",
        body: dto,
      }),
      invalidatesTags: [ApiTags.PROMOTIONAL_CODES],
    }),
    updatePromotionalCode: builder.mutation<
      void,
      UpdatePromotionalCodeDto & { code: string }
    >({
      query: ({ code, ...dto }) => ({
        url: `promotional-codes/${code}`,
        method: "PATCH",
        body: dto,
      }),
      invalidatesTags: [ApiTags.PROMOTIONAL_CODES],
    }),
    deletePromotionalCode: builder.mutation<void, string>({
      query: (code) => ({
        url: `promotional-codes/${code}`,
        method: "DELETE",
      }),
      invalidatesTags: [ApiTags.PROMOTIONAL_CODES],
    }),
    updateProject: builder.mutation<
      boolean,
      UpdateProjectDto & UpdateProjectPathParams
    >({
      query: ({ id, ...body }) => ({
        url: `projects/${id}`,
        method: "PATCH",
        body,
      }),
      invalidatesTags: [ApiTags.PROJECTS],
    }),
    setDesignAssignmentDueDate: builder.mutation<
      DesignAssignment,
      SetDesignAssignmentDueDateArguments
    >({
      query: (dto) => ({
        url: `rpc/design-assignment/calculate-and-set-due-date`,
        method: "POST",
        body: dto,
      }),
      invalidatesTags: [ApiTags.DESIGN_ASSIGNMENTS],
    }),
    // BUILD REGIONS
    getBuildRegionsByContractorId: builder.query<
      BuildRegion,
      BuildRegionsByContractorIdPathParams
    >({
      query: ({ contractorId }) => ({
        url: `${contractorId}/contractors`,
        method: "GET",
      }),
      providesTags: [ApiTags.BUILD_REGIONS],
    }),
    getBuildRegionsById: builder.query<BuildRegion, BuildRegionsByIdPathParams>(
      {
        query: ({ id }) => ({
          url: `build-regions/${id}`,
          method: "GET",
        }),
        providesTags: [ApiTags.BUILD_REGIONS],
      }
    ),
    createBuildRegion: builder.mutation<
      BuildRegion,
      CreateBuildRegionsDto & { updatedByEmail: string }
    >({
      query: (body) => ({ url: `build-regions`, method: "POST", body }),
    }),
    updateBuildRegion: builder.mutation<
      boolean,
      UpdateBuildRegionsDto &
        BuildRegionsUpdatePathParams & { updatedByEmail: string }
    >({
      query: ({ id, ...body }) => ({
        url: `build-regions/${id}`,
        method: "PATCH",
        body,
      }),
      invalidatesTags: [ApiTags.BUILD_REGIONS],
    }),
  }),
});
