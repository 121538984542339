import { useMediaQuery } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import Drawer from "@material-ui/core/Drawer";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Menu from "@material-ui/icons/Menu";
import Leave from "@material-ui/icons/TimeToLeave";
import { theme, YZTypography } from "@yardzen-inc/react-common";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import * as React from "react";
import { Route, RouteComponentProps, Switch } from "react-router";
import SearchableTable from "../ClientTable/SearchableTable";
import GenericSnackBar from "../Components/GenericSnackBar";
import LoadingAbsCenter from "../Components/LoadingAbsCenter";
import DesignProfiles from "../DesignProfiles";
import { ErrorBoundary } from "../util/ErrorBoundry";
import RecordsCtx from "../util/RecordsContext";
import { UserCtx } from "../util/UserContext";
import AgentManagement from "./AgentManagment";
import ElementLibrary from "./AssetLibraries/ElementLibrary";
import ElementsLibraryCreate from "./AssetLibraries/ElementLibrary/ElementsLibraryCreate";
import PlantLibrary from "./AssetLibraries/PlantLibrary";
import PlantsLibraryCreate from "./AssetLibraries/PlantLibrary/PlantLibraryCreate";
import SurfaceLibrary from "./AssetLibraries/SurfaceLibrary";
import SurfaceLibraryCreate from "./AssetLibraries/SurfaceLibrary/SurfaceLibraryCreate";
import Assignments from "./Assignments";
import { CDM } from "./CDM";
import Dashboard from "./Dashboard";
import DssQueue from "./DssQueue";
import { IncompleteAddressQueue } from "./IncompleteAddressQueue/IncompleteAddressQueue";
import { MssQueue } from "./MssQueue";
import MyProjects from "./MyProjects";
import { NewDesignProfileView } from "./NewDesignProfileView";
import { PostPLPHoldsView } from "./PostPLPHolds";
import ProjectManagers from "./ProjectManagers";
import { QAPage } from "./QA";
import StatsPage from "./Stats";
import Index from "./ZipCodeManagement/index";

import {
  AccountCircle,
  Assessment,
  Assignment,
  AssignmentInd,
  Contacts,
  DashboardOutlined,
  Deck,
  GridOn,
  ListAlt,
  ListSharp,
  LocationOff,
  Nature,
  PermMedia,
  PinDrop,
  QuestionAnswer,
  RateReviewSharp,
  Work,
} from "@material-ui/icons";
import { MarketManagerPage } from "./MarketManager/MarketManagerPage";
import { PromotionalCodeManager } from "./PromotionalCodeManager/PromotionalCodeManager";

const BuildViewRouter = React.lazy(() => import("./BuildView/BuildViewRouter"));

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      [theme.breakpoints.down("sm")]: {
        maxWidth: "100vw",
        marginTop: "3rem",
      },
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "center",
      minHeight: "3rem",
      background: theme.palette.primary.light,
    },
    appBarDesktop: {
      padding: "0.5rem",
      borderBottom: `1px solid ${theme.palette.grey[200]}`,
      background: "#fff",
      height: 43,
    },
    drawerDesktop: {
      width: drawerWidth,
      borderRight: `1px solid ${theme.palette.grey[200]}`,
      position: "fixed",
      left: 0,
      height: "100%",
    },
    drawer: {
      width: drawerWidth,
    },
    drawerPaper: {
      width: drawerWidth,
      flexShrink: 0,
      position: "relative",
      [theme.breakpoints.up("sm")]: {
        height: "calc(100vh - 3rem)",
      },
    },
    content: {
      flexGrow: 1,
      [theme.breakpoints.up("sm")]: {
        marginLeft: 240,
        width: "calc(100vw - 256px)",
      },
      [theme.breakpoints.down("sm")]: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
      },
    },
    iconContainer: {
      minWidth: "2rem",
      "& svg": {
        fontSize: "1.2rem",
      },
    },
  })
);

export default function EmployeeHome(props: any) {
  const classes = useStyles();
  const UserContext = React.useContext(UserCtx);
  const recordsContext = React.useContext(RecordsCtx);
  const [drawerOpen, setDrawerOpen] = React.useState<boolean>(false);
  const error = null;

  const smDown = useMediaQuery((theme) =>
    (theme as Theme).breakpoints.down("sm")
  );

  const TabsList = () => {
    const list = [
      {
        icon: <DashboardOutlined />,
        name: "Dashboard",
        route: "/",
      },
      {
        icon: <AssignmentInd />,
        name: "Agent Management",
        route: "/agents",
      },
      {
        icon: <Assignment />,
        name: "Assignments",
        route: "/assignments",
      },
      {
        icon: <ListAlt />,
        name: "Designer Self Assign Queue",
        route: "/dss",
      },
      {
        icon: <ListSharp />,
        name: "Modeler Self Assign Queue",
        route: "/mss",
      },
      {
        icon: <Contacts />,
        name: "Client Table",
        route: "/client-table",
      },
      {
        icon: <PermMedia />,
        name: "Design Profiles",
        route: "/design-profiles",
      },
      {
        icon: <PermMedia />,
        name: "Design Profiles (onboard 2)",
        route: "/new-design-profiles",
      },
      {
        icon: <Assignment />,
        name: "CDM",
        route: "/cdm",
      },
      {
        icon: <RateReviewSharp />,
        name: "PLP Review",
        route: "/post-plp-holds",
      },
      {
        icon: <QuestionAnswer />,
        name: "QA",
        route: "/qa",
      },
      {
        icon: <AccountCircle />,
        name: "Project Managers",
        route: "/pms",
      },
      {
        icon: <Work />,
        name: "My Projects",
        route: "/my-projects",
      },
      {
        icon: <Assessment />,
        name: "Stats",
        route: "/ stats",
      },
      {
        icon: <PinDrop />,
        name: "Zip Code /  Geo Management",
        route: "/zip-code-geo-management",
      },
      {
        icon: <LocationOff />,
        name: "Incomplete Address Queue ",
        route: "/incomplete-address-queue",
      },
      {
        icon: <Deck />,
        name: "Elements Library",
        route: "/elements-library",
      },
      {
        icon: <GridOn />,
        name: "Materials Library",
        route: "/materials-library",
      },
      {
        icon: <Nature />,
        name: "Plants Library",
        route: "/plants-library",
      },
      {
        icon: <Nature />,
        name: "Market Manager",
        route: "/markets",
      },
      {
        icon: <Nature />,
        name: "Promotional Code Manager",
        route: "/promotional-codes",
      },
    ];

    return list;
  };

  return (
    <>
      {error && (
        <GenericSnackBar
          orientation={{
            vertical: "bottom",
            horizontal: "left",
          }}
          message={error}
          variant="error"
          onClose={() => {}}
        />
      )}
      {smDown && (
        <AppBar position="fixed" className={classes.appBar}>
          <YZTypography
            variant="h5"
            noWrap
            style={{ marginLeft: "1rem", color: theme.palette.text.primary }}
          >
            Liisa 2.0
          </YZTypography>
          <IconButton
            onClick={() => setDrawerOpen(true)}
            style={{
              marginLeft: "auto",
              color: "white",
              height: "fit-content",
              width: "fit-content",
              marginRight: "1rem",
            }}
          >
            <Menu />
          </IconButton>
          {renderDrawer()}
        </AppBar>
      )}
      <div className={classes.root}>
        {!smDown && (
          <div className={classes.drawerDesktop}>
            <Typography variant="h6" noWrap className={classes.appBarDesktop}>
              Liisa 2.0
            </Typography>
            {renderDrawer()}
          </div>
        )}
        <main
          className={classes.content}
          style={
            window.location.pathname.includes("assignments")
              ? { paddingLeft: "8px", paddingRight: "8px" }
              : undefined
          }
        >
          <React.Suspense fallback={<LoadingAbsCenter in />}>
            <div>
              <Switch>
                <Route
                  exact
                  path={`/`}
                  render={() => (
                    <ErrorBoundary>
                      <Dashboard projects={recordsContext?.records} />
                    </ErrorBoundary>
                  )}
                />
                <Route
                  path={`/agents`}
                  render={() => (
                    <ErrorBoundary>
                      <AgentManagement />
                    </ErrorBoundary>
                  )}
                />
                <Route
                  path={`/dss`}
                  render={() => (
                    <ErrorBoundary>
                      <DssQueue />
                    </ErrorBoundary>
                  )}
                />
                <Route
                  path={`/mss`}
                  render={() => (
                    <ErrorBoundary>
                      <MssQueue />
                    </ErrorBoundary>
                  )}
                />

                <Route path={`/stats`} render={() => <StatsPage />} />
                <Route
                  path="/design-profiles"
                  render={() => (
                    <ErrorBoundary>
                      <DesignProfiles
                        history={props.history}
                        match={props.match}
                        location={props.location}
                      />
                    </ErrorBoundary>
                  )}
                />
                <Route
                  path="/my-projects"
                  render={() => (
                    <ErrorBoundary>
                      <MyProjects />
                    </ErrorBoundary>
                  )}
                />
                <Route
                  path="/new-design-profiles"
                  render={() => (
                    <ErrorBoundary>
                      <NewDesignProfileView />
                    </ErrorBoundary>
                  )}
                />

                <Route
                  path="/build"
                  render={() => (
                    <ErrorBoundary>
                      <BuildViewRouter />
                    </ErrorBoundary>
                  )}
                />
                <Route
                  path="/pms"
                  render={() => (
                    <ErrorBoundary>
                      <ProjectManagers
                        history={props.history}
                        match={props.match}
                        location={props.location}
                      />
                    </ErrorBoundary>
                  )}
                />
                <Route
                  path="/cdm"
                  render={(routeProps) => (
                    <ErrorBoundary>
                      <CDM {...routeProps} />
                    </ErrorBoundary>
                  )}
                />
                <Route
                  path="/post-plp-holds"
                  render={(routeProps) => <PostPLPHoldsView {...routeProps} />}
                />
                <Route path="/qa" component={QAPage} />
                <Route
                  path={`/client-table`}
                  render={(routeProps: RouteComponentProps) => (
                    <ErrorBoundary>
                      <SearchableTable {...routeProps} />
                    </ErrorBoundary>
                  )}
                />
                <Route
                  path={`/assignments`}
                  render={(rcprops) => <Assignments {...rcprops} />}
                />
                <Route
                  path="/zip-code-geo-management"
                  render={(rcprops) => <Index {...rcprops} />}
                />
                <Route
                  path="/incomplete-address-queue"
                  render={() => <IncompleteAddressQueue />}
                />

                <Route
                  exact
                  path={`/elements-library/create`}
                  render={() => <ElementsLibraryCreate />}
                />

                <Route
                  path={`/elements-library`}
                  render={() => <ElementLibrary />}
                />

                <Route
                  path={`/materials-library/create`}
                  render={() => <SurfaceLibraryCreate />}
                />

                <Route
                  path={`/materials-library`}
                  render={() => <SurfaceLibrary />}
                />

                <Route
                  path={`/plants-library/create`}
                  render={() => <PlantsLibraryCreate />}
                />

                <Route
                  path={`/plants-library`}
                  render={() => <PlantLibrary />}
                />
                <Route path={`/markets`} render={() => <MarketManagerPage />} />
                <Route
                  path={`/promotional-codes`}
                  render={() => <PromotionalCodeManager />}
                />
              </Switch>
            </div>
          </React.Suspense>
        </main>
      </div>
    </>
  );

  function handleNavigate(to: string) {
    if (to === "/") {
      return props.history.push({
        pathname: `/`,
      });
    }

    props.history.push({
      pathname: `${to}`,
    });
  }

  function renderDrawer() {
    return (
      <Drawer
        onClose={() => setDrawerOpen(false)}
        className={classes.drawer}
        open={drawerOpen}
        variant={smDown ? "temporary" : "permanent"}
        PaperProps={{ id: "employee-home-sidebar" }}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <List>
          {TabsList().map((text, index) => {
            if (
              text.name === "Admin" &&
              !!UserContext &&
              !UserContext.email?.includes("yardzen.co")
            ) {
              return <></>;
            }
            return (
              <ListItem
                onClick={() => {
                  handleNavigate(text.route);
                  setDrawerOpen(false);
                }}
                selected={props.location.pathname === text.route}
                button
                key={text.route}
              >
                <ListItemIcon className={classes.iconContainer}>
                  {text.icon}
                </ListItemIcon>
                <ListItemText primary={text.name} />
              </ListItem>
            );
          })}
          <ListItem
            onClick={() => {
              firebase.auth().signOut();
            }}
            button
            key={"logout"}
          >
            <ListItemIcon className={classes.iconContainer}>
              <Leave />
            </ListItemIcon>
            <ListItemText primary={"Log Out"} />
          </ListItem>
        </List>
      </Drawer>
    );
  }
}
